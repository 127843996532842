import Heading from '@/components/Heading.vue';
import IdentificationMethods from '@/components/identification/IdentificationMethods.vue';
import ResponsiveImage from '@/components/ResponsiveImage.vue';
import ToolbarBase from '@/components/ToolbarBase.vue';
import IdentificationType from '@/pages/profile/signature-standards/zertes/index.vue';
export default defineComponent({
  components: {
    ToolbarBase: ToolbarBase,
    Heading: Heading,
    IdentificationMethods: IdentificationMethods,
    ResponsiveImage: ResponsiveImage
  },
  extends: IdentificationType,
  matomo: false,
  created: function created() {
    var _this$$matomo, _this$$matomo2;
    (_this$$matomo = this.$matomo) === null || _this$$matomo === void 0 ? void 0 : _this$$matomo.setDocumentTitle('NAS Ident - ZertES');
    (_this$$matomo2 = this.$matomo) === null || _this$$matomo2 === void 0 ? void 0 : _this$$matomo2.trackPageView();
  }
});